export const PLAN_NAMES = ["free", "legacy free", "basic", "premium"];

export const LANGUAGE_NAMES = ["python", "r", "sql", "cli"];

export const LESSON_TYPES = {
  mission: "Lesson",
  project: "Guided project",
  portfolio_project: "Portfolio project",
  practice_problem: "Practice problem",
  assessment: "Assessment",
};

export const FEATURES = [
  "PowerBI",
  "Excel",
  "Excel Online",
  "Tableau",
  "pandas",
  "dataviz",
  "Postgres",
  "JupyterLab",
  "Stateful",
  "GPU",
  "Chat temperature",
  "File viewer",
  "Long-running code",
  "High CPU",
  "High Memory",
];

export const FILE_VIEWER_MODE = "filescreen";

export const FEATURES_WO_PACKAGES = [
  "Excel",
  "Excel Online",
  "Tableau",
  "PowerBI",
  "File viewer",
];

export const SKILL_LEVEL_MAP = {
  0: "Beginner",
  1: "Intermediate",
  2: "Advanced",
};

export const LESSON_METADATA_FIELDS = [
  "allowed_plans",
  "app_ports",
  "author",
  "title",
  "meta_title",
  "description",
  "meta_description",
  "overview",
  "preview",
  "is_beta",
  "features",
  "code_run_timeout",
  "objectives",
  "type",
  "related_lesson",
  "related_lessons",
  "tools",
  "skill_level",
  "skill_topics",
  "skill_requirements",
  "language",
  "language_version",
];

export const MODIFIED_STATUS = "Modified";

export const DEFAULT_PYTHON_VERSION = "3.11";

export const PYTHON_VERSIONS = ["3.8", "3.9", "3.11"];

export const MAX_FILE_SIZE_MB = 50;

export const SQL_MAX_ROWS = 20;
