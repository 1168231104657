import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Alert, Link } from "@mui/material";
import { timestampToString } from "../utils";
import { CircularProgress } from "@mui/material";

function DeployNotification(props) {
  const [latestDeploy, setLatestDeploy] = useState();
  const params = useParams();

  const renderJobNumber = () => {
    if (latestDeploy.build_number)
      return (
        <Link href={latestDeploy.job_url} target="_blank" rel="noopener">
          {`job #${latestDeploy.build_number}`}
        </Link>
      );
    return <CircularProgress size={16} />;
  };

  const getLatestDeploy = async () => {
    const response = await fetch(
      `/api/lesson/${params.lessonId}/deploy/latest`
    );
    if (response.status === 200) {
      const latestDeploy = await response.json();
      setLatestDeploy(latestDeploy);
    }
  };

  useEffect(() => {
    if (props.newLessonDeploy) {
      setLatestDeploy(props.newLessonDeploy);
    }
  }, props.newLessonDeploy);

  useEffect(() => {
    getLatestDeploy();
    const interval = setInterval(getLatestDeploy, 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (!latestDeploy) {
    return null;
  } else if (latestDeploy.status === "running" || !latestDeploy.status) {
    return <Alert severity="info">Deploying lesson {renderJobNumber()}</Alert>;
  } else {
    const severity = latestDeploy.status === "failed" ? "warning" : "success";
    const status = latestDeploy.status === "failed" ? "failed" : "succeeded";
    return (
      <Alert severity={severity}>
        Last deploy <strong>{status}</strong> {renderJobNumber()} —{" "}
        {timestampToString(latestDeploy.timestamp)}
      </Alert>
    );
  }
}

export default DeployNotification;
