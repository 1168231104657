import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useDispatch } from "react-redux";
import { lessonDataActions } from "../../store/lessonDataSlice";
import SimpleCodeTab from "./SimpleCodeTab";

export default function CliAnswerValueTab({ lesson, screen }) {
  const dispatch = useDispatch();

  const handleChange = (event) => {
    dispatch(
      lessonDataActions.updateScreenField({
        screenId: screen.id,
        field: "check_scope",
        value: event.target.value === "" ? null : event.target.value,
      })
    );
  };

  return (
    <>
      <SimpleCodeTab lesson={lesson} screen={screen} field={"check_val"} />
      <br />
      <FormControl fullWidth>
        <InputLabel id="answer-scope-select-label">Answer Scope</InputLabel>
        <Select
          labelId="answer-scope-select-label"
          id="answer-scope-select"
          value={screen.check_scope || ""}
          label="Answer Scope"
          onChange={handleChange}
        >
          <MenuItem value="cmd">Command</MenuItem>
          <MenuItem value="output">Output</MenuItem>
          <MenuItem value={""}>Unset</MenuItem>
        </Select>
      </FormControl>
    </>
  );
}
